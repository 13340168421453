@import url(./reset.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body,
#root,
.landing {
    /*display: flex;*/
    display: block;
    overflow-x: hidden;
    font-family: Roboto, sans-serif;
    width: 100%;
    height: 100%;
}

#root {
    display: flex
}

div {
    font-family: Roboto, sans-serif;
}

html {
    width: 100%;
    height: 100%;
}

.pre-loader {
    margin: 30px;
    border: 0 !important;
}

.profile-form {
    height: auto;
}

.invisible {
    visibility: hidden;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #80c700;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loader--backdrop {
    background: rgba(0, 0, 0, 0.5);
}

.spinner {
    position: relative;
    width: 40px;
    height: 40px;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
}

.scrollable-block::-webkit-scrollbar {
    width: 10px;
    background-color: #f0f0f0;
    /* make scrollbar transparent */
}

.scrollable-block::-webkit-scrollbar-thumb {
    background: #b0b0b0;
}

.login-modal > div > div > div > div:nth-child(2) {
    min-height: 120px;
}

@media screen and (max-height: 480px) {
    .insurance-modal > div > div > div > div:nth-child(2) {
        min-height: 175px;
    }

    .insurance-modal {
        padding-top: 0 !important;
    }
}

.duration-slider .divider .divider-label {
    margin-left: -30px;
}

.duration-slider .divider > div:first-child .divider-label {
    margin-left: -8px;
}

.slick-slider {
    cursor: auto !important;
}

input:invalid {
    box-shadow: none;
}
